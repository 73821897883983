import Dog from "../img/officers/dog.png";
import Alvaro from "../img/officers/alvaro.jpg"; 
import Dan from "../img/officers/dan.jpg"; 
import Destiny from "../img/officers/destiny.jpg"; 
import Ilan from "../img/officers/ilan.jpg";  
import Liam from "../img/officers/liam.jpg";
import Sai from "../img/officers/sai.jpg";
import Syrus from "../img/officers/syrus.png";
import Valeria from "../img/officers/valeria.jpg";
import Vicki from "../img/officers/vicki.jpg";
import Jackson from "../img/officers/monkey.avif";
export const Officers = [
  {
    name: "Hitarth Thanki",
    role: "Director",
    avatar: Dog,
    linkedIn: "https://linkedin.com/in/hitarth-thanki"
  },
  {
    name: "Valeria Duarte",
    role: "Director of Coroprate Relations",
    avatar: Valeria,
    linkedIn: "https://www.linkedin.com/in/valeriaaduaarte/"
  },
  {
    name: "Sai Bharani",
    role: "Director of Internal Affairs",
    avatar: Sai,
    linkedIn: "https://www.linkedin.com/in/sai-bharani-veerepalli-5b5252265/"
  },
  {
    name: "Baqir Salim",
    role: "Director of Logistics",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/baqir-salim-887094215/"
  },
  {
    name: "Ilan Lipsky",
    role: "Director of Logistics",
    avatar: Ilan,
    linkedIn: "https://www.linkedin.com/in/ilan-lipsky-43392178/"
  },
  {
    name: "Destiny Gutierrez",
    role: "Director of Marketing and Design",
    avatar: Destiny,
    linkedIn: "https://www.linkedin.com/in/destiny-gutierrez-23abb3260/"
  },
  {
    name: "Jackson Nace",
    role: "Tech Lead",
    avatar: Jackson,
    linkedIn: "https://www.linkedin.com/in/jacksonnace/"
  },
  {
    name: "Yash Patel",
    role: "Corporate Relations",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/yashpatelll/"
  },
  {
    name: "Syrus Tolentino",
    role: "Corporate Relations",
    avatar: Syrus,
    linkedIn: "https://www.linkedin.com/in/syrus-tolentino/"
  },
  {
    name: "Liam Le",
    role: "Internal Affairs",
    avatar: Liam,
    linkedIn: "https://www.linkedin.com/in/liamlecs/"
  },
  {
    name: "Victoria Bayang",
    role: "Internal Affairs",
    avatar: Vicki,
    linkedIn: "https://www.linkedin.com/in/victoria-bayang/"
  },
  {
    name: "Alvaro Urtaza",
    role: "Logistics",
    avatar: Alvaro,
    linkedIn: "https://www.linkedin.com/in/alvaro-urtaza/"
  },
  {
    name: "Dan Pham",
    role: "Logistics",
    avatar: Dan,
    linkedIn: "https://www.linkedin.com/in/dan-pham-9870a223b/"
  },
  {
    name: "Shahad Alghamdi",
    role: "Logistics",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/shahad-ghamdi/"
  },
  {
    name: "Omoyeni Kuku",
    role: "Marketing",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/omoyeni-kuku-889292247/"
  },
  {
    name: "David Odejimi",
    role: "Designer",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/david-odejimi/"
  },
  {
    name: "Henry Nguyen",
    role: "Designer",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/duc-nguyen-b27425287/"
  },
  {
    name: "Rohan Chaudhary",
    role: "Web Developer",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/rohan-codes/"
  },
  {
    name: "Neha Joshi",
    role: "Web Developer",
    avatar: Dog,
    linkedIn: "https://www.linkedin.com/in/nehakjoshi/"
  },
]