import React from 'react';
import './AboutUs.css'; // Create and add styles in AboutUs.css

function AboutUs() {
  return (
    <div className="about-us">
      <h1>About Us</h1>
      <p>
        CodeRED is Houston's biggest, best, and premier hacking experience. We are also one of the most diverse hackathons in Texas. With nine years of experience, we are proud to present our ninth annual hacking experience, CodeRED Astra!
      </p>
      <p>
        At CodeRED Astra, we are committed to fostering a vibrant tech community through innovative and challenging projects. Join us to be part of an event that pushes the boundaries of what's possible and celebrates diversity and creativity in the tech world.
      </p>
    </div>
  );
}

export default AboutUs;