import "./hero.css";
import logo from './img/logo.svg';
import Countdown from './countdown.js';
import herobutton from './img/herobutton.png'

function Hero() {
    const hackathonDate = 'October 12 - 13'; // Replace with your actual hackathon date

    return (
        <div className='hero'>
            <img src={logo} className="App-logo" alt="Hackathon Logo" />
            <Countdown/>
            <div className="hackathon-date">
                <h3>{hackathonDate}</h3>
            </div>
            <div className="ButtonLinks">
                <div className="button2">
                    <a href="https://forms.gle/ztTdJmMcoLUSTWN57" target="_blank" rel="noopener noreferrer scale-box">
                        <div className="text-box">
                            <h4>Apply Now!</h4>
                        </div>
                        <img src={herobutton} alt="Apply Now" />
                    </a>
                </div>
                {/* <div className="button2">
                    <a href="https://www.youtube.com/watch?v=PKocF-P4Phs" target="_blank" rel="noopener noreferrer">
                        <div className="text-box">
                            <h4>Join our discord!</h4>
                        </div>
                        <img src={herobutton} className="discordbutton" alt="Join Discord" />
                    </a>
                </div> */}
            </div>
        </div>
    );
}


// function Hero() {
//     return(
//         <div className='hero'>
//             <img src={logo} className="App-logo" alt="" />
//                 <Countdown/>
//             <div className="ButtonLinks">
//                 <div className="button2">
//                     <a href="https://forms.gle/ztTdJmMcoLUSTWN57" target="_blank">
//                         <div class="text-box">
//                             <h4>Apply Now!</h4>
//                         </div>
//                         <img src={herobutton} className="registerbutton" alt=""/>
//                     </a>
//                 </div>
//                 {/* <div className="button2">
//                     <a href="https://www.youtube.com/watch?v=PKocF-P4Phs" target="_blank">
//                         <div class="text-box">
//                             <h4>Join our discord!</h4>
//                         </div>
//                         <img src={herobutton} className="discordbutton" alt=""/>
//                     </a>
//                 </div> */}
//             </div>
//         </div>
//     );
// }

// the discord button can be added in later as an announcement

export default Hero;