import React, { useState } from 'react';
import './faq.css';

const faq = [
  {
    category: "General",
    question_list: [
      {
        question: 'What is a hackathon? Will there be actual hacking?',
        answer: 'A hackathon is an event where students come together to "hack" and create apps, websites, games, etc., within 24-48 hours. There will be no malicious hacking involved.',
      },
      {
        question: 'Is the event free to attend?',
        answer: 'Yes! The event is free, and food will be provided throughout. We will also have swag and prizes!',
      },
      {
        question: 'Where is the event? Is it in person or virtual? Where can I park?',
        answer: 'The event will be hosted in person in Houston. More details, including parking information, will be provided very soon. :D',
      },
      {
        question: 'What should I bring?',
        answer: 'Bring your laptop, charger, headphones, deodorant, pillow/blanket, and anything else you might need!',
      },
      {
        question: 'Do I have to stay overnight?',
        answer: 'No, you can leave and come back if you would prefer to go to bed.',
      }
    ],
  },
  {
    category: "Teaming",
    question_list: [
      {
        question: 'What is the team size limit?',
        answer: "Teams can have up to 4 members. After registrations close, we'll open up our Discord server to help hackers connect with like-minded individuals and form teams!"
      },
      {
        question: "Can I register with a premade team?",
        answer: "Yes! You are able to register with a premade team. IMPORTANT: All members must sign up, with the same team name. Otherwise, they risk not being able to enter the hackathon due to space."
      }
    ],
  },
  {
    category: "Other",
    question_list: [
      {
        question: "Who can attend? What if I don't have any experience? Do I have to be 18?",
        answer: 'This event is open to all students and is extremely beginner-friendly. We offer workshops to help you learn during the event and have mentors available to assist you with your project. Attendees must be at least 18 years old to participate.',
      },
      {
        question: 'Are there travel reimbursements?',
        answer: 'Unfortunately, we are not able to provide travel reimbursements at this time.',
      },
      {
        question: 'When can we start working on our project? Can I work on a previous project?',
        answer: 'You can only start working on your project after the opening ceremony. While you can come up with ideas beforehand, coding cannot begin until then. You cannot work on a previous project, but you may use frameworks as long as you clearly credit them in your README and differentiate between what you created and what you used.',
      },
      {
        question: 'What kind of activities will there be?',
        answer: 'We will post the schedule closer to the event. There will be workshops, activities to take breaks, and opportunities to meet other hackers and our wonderful sponsors!',
      },
      {
        question: 'Will hardware be available?',
        answer: 'We do not provide hardware, but you are welcome to bring your own. Due to building fire codes, please refrain from bringing soldering kits!',
      },
      {
        question: 'Are you sending out acceptances? Is there a deadline to apply? Is there a waitlist?',
        answer: 'We will start sending out acceptance notifications once registrations close and a few weeks before the event. If you need to know before then to book travel, please reach out to our team for an early review at internal.affairs@cougarcs.com. Applications will close once we reach the maximum number of hackers we can support, but we will have a waitlist on the day of the event for local hackers who want to fill spots if any accepted hackers do not attend.',
      },
       {
         question: 'How do I sign up to be a mentor/judge?',
         answer: 'We will be releasing forms for this soon, so please check back later!',
      },
      {
        question: 'I still have a question. Who can I contact?',
        answer: 'Email us at internal.affairs@cougarcs.com or message us on our social media! (Links are in the footer)',
      }
    ]
  }
];

function FAQ() {
  const [expanded, setExpanded] = useState(null);

  const toggleFAQ = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className='container'>
      <p className="title-card">FAQs</p>
      <div className='faq-container'>
        {
          faq.map((category, categoryIndex) => (
            <div key={categoryIndex} className='category-card'>
                <p className='category'>
                  {category.category}
                </p>
                <section className='questions-container'>
                  {category.question_list.map((faq, faqIndex) => (
                    <div 
                      key={faqIndex} 
                      onClick={() => toggleFAQ(`${categoryIndex}-${faqIndex}`)}
                      className={`question ${expanded === `${categoryIndex}-${faqIndex}` ? 'question-expanded' : ''}`}
                    >
                      <p className=''>
                        {faq.question}
                      </p>
                      {expanded === `${categoryIndex}-${faqIndex}` && <p className='answer-text'>{faq.answer}</p>}
                    </div>
                  ))}
                  
                </section>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default FAQ;
