import React from 'react';
import "./sponsor.css";
import logo from './img/logo.svg';

function Sponsor() {
  return (
    <div>
        <div className='sponsors'>
          <h1 className='sponsorTitle'>Sponsors</h1>
          <h3>Coming soon!</h3>
          {/* <p className='sponsormessage'>If you would like to sponsor CodeRED Astra, please reach out to
          <a classname='emailaddress' href='codered-corporate-relations@uhcode.red'> codered-corporate-relations@uhcode.red</a> ASAP</p>
          <div className='sponsorBoxes'>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            <a href="" target="_blank"><div className='sponsorBox'><img src={logo} className="sponsorpic" alt="logo" /></div></a>
            
          </div> */}
        </div>
    </div>
  )
}

export default Sponsor;
