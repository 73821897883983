import React from 'react';
import './footer.css';
// import instagram from './img/instagram.png';
// import linkedin from './img/linkedin.png'
// import discord from './img/discord.png'
// import email from './img/email.png'
import instagram from './img/instagram2.png';
import linkedin from './img/linkedin2.png'
import email from './img/email2.png'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='sb_footer section_padding'>
        <div className='sb_footer-links'>

          <div className='sb_footer-links-div'>
            <h4>Resources</h4>
            <a href=""><p>Coming Soon!</p></a>
          </div>

          <div className='sb_footer-links-div'>
            <h4>More Hackathons</h4>
            <a href="https://hacksmu.org/" target="_blank"><p>Hack SMU VI - Oct 5 & 6</p></a>
            <a href="https://ripple.hackutd.co/" target="_blank"><p>HackUTD - Nov 16 & 17</p></a>
          </div>

          <div className='sb_footer-links-div'>
            <h4>Socials</h4>
            <div className='socialmedia'>
              <a href="https://www.linkedin.com/company/coderedhouston/mycompany/" target="_blank"><p><img src={linkedin} alt=''/></p></a>
              <a href="https://www.instagram.com/codered_hou?igsh=cmwxYTJ6dng1cTBx" target="_blank"><p><img src={instagram} alt=''/></p></a>
              {/* <a href="https://www.youtube.com/watch?v=PKocF-P4Phs" target="_blank"><p><img src={discord} alt=''/></p></a> */}
              <a href="codered-corporate-relations@uhcode.red" target="_blank"><p><img src={email} alt=''/></p></a>
            </div>
          </div>
        </div>

      <hr></hr>

      <div className='sb_footer-below'>
        <div className='sb_footer-copyright'>
          <p>
            &copy; {new Date().getFullYear()} CodeRED Team.
          </p>
        </div>
      </div>

      </div>
    </div>
  )
}

export default Footer
