import React from 'react';
import "./officer.css";
import { Officers } from './utilis/officers';


function Officer() {
  return (
    <div className='container'>
      <p className='title'>Officers</p> 
      <ul className='officer-grid'>
        {
          Officers.map((officer, index) => (
            <a href={officer.linkedIn} target="_blank" rel="noopener noreferrer">
              <li key={index} className='officer-card'>
                <img className='officer-image' src={officer.avatar} alt={officer.name}/>
                <div className='officer-name'>{officer.name}</div>
                <div className='officer-role'>{officer.role}</div>
              </li>
            </a>
          ))
        } 
      </ul>
    </div>
  );
}
export default Officer;
